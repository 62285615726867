import React, { useEffect, useRef } from "react";
import './login.css'

export default function Login(props) {
  const containerRef = useRef(null);
  const poundContainer = useRef(null);
  const poundRefTop = useRef(null);
  const poundRefBot = useRef(null);


  useEffect(() => {
    const nrOfPounds = Math.floor(poundContainer.current.clientWidth / 6);
    console.log(`nrOfPounds: ${nrOfPounds}`);
    const poundContent = [];

    setTimeout(() => {
      containerRef.current.classList.remove('load-fade');
    }, 450);

    const id = setInterval(() => {
      if(poundContent.length < nrOfPounds) {
        poundContent.push('#');
        poundRefTop.current.innerHTML = poundContent.join('');
        poundRefBot.current.innerHTML = poundContent.join('');
      }else{
        clearInterval(id);
      }
    }, 2)

    return () => {
      clearInterval(id);
    }
  }, [])

  return (
    <div ref={containerRef} className="h-screen crt login-page flex justify-center items-center">
      <div className="h-1/2 w-full p-2 md:p-0 md:w-1/2 flex flex-col items-center">
        <div className="uppercase text-4xl ">Access Request</div>
        <div ref={poundContainer} className="flex flex-row justify-center w-full pound-line">
          <div ref={poundRefTop}></div>
        </div>
        <div className="flex-1 w-full flex flex-col items-center justify-center">
          <div>///////////////// \\\\\\\\\\\\\\\\\</div>
          <div className="text-2xl">Enter Username</div>
          <div className="">
            <input className="login-input text-center uppercase" type="text" />
          </div>
          <div className="text-2xl">Enter Password</div>
          <div className="">
            <input className="login-input text-center" type="password" />
          </div>
          <div className="login-button cursor-pointer m-2 uppercase w-44 bg-red-500 text-black text-center">Login</div>
          <div>\\\\\\\\\\\\\\\\\ /////////////////</div>

        </div>
        <div className="flex flex-row justify-center w-full pound-line">
          <div ref={poundRefBot}></div>
        </div>
      </div>
    </div>
  )
}