import React, {useState, useCallback} from "react"
import {Link} from "react-router-dom"
import image from '../../assets/center.png';
import axionAlt from '../../assets/AxionAltLogo.png';


export default function Home(props) {
  const [show, setShow] = useState(false)

  const clickHandler = useCallback(() => {
    setShow(!show)
  }, [show])

  return (
    <div className="App h-screen w-screen flex justify-center items-center">
      <div className='h-5/6 flex justify-center w-5/6'>
        <img src={image} alt='center' className='object-contain h-full'/>
      </div>
      {show && <div className='absolute right-0 top-auto bottom-auto w-full p-2 bg-black text-white text-center border-y-2 border-y-red-800 
        lg:border-y-transparent lg:border-y-0 lg:bg-transparent lg:p-0 lg:right-5 lg:text-right lg:w-40'>
        We, Axion.<span className='text-red-600'>RED</span>, are building the next generation of software for a better tommorow.
      </div> }
      <Link className="bottom-5 right-5 absolute" to="/login">
        <img src={axionAlt} alt='axion' className='object-contain w-20 cursor-pointer'/>
      </Link>
    </div>
  );
}